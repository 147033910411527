import React, { useState } from 'react';
import { useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom'

import { axiosService } from '@/_services/account.axios';
import './bulletinAdhesion.css';
import HeaderPublic from '@/components/header/HeaderPublic';
import '@/components/logo/logo.css';

const Contact = () => {
    const navigate = useNavigate();
    const [flagSaisie,setFlagSaisie] = useState('saisie');

    const [messageAR,setMessageAR] = useState('');
  
    //valeurs par défaut pour le formulaire
    const [initialValues, setInitialValues] = useState( {      
        nom: '',
        prenom: '',
        dateNaissance:'',
        telephone:'',
        adresse: '',
        complements: '',
        codePostal: '',
        ville: '',
        situation:'demandee',
        idProfil: 9,    // adhérent
        emailValide: 1,
        domaine: 'front',
        action: 'setNewAdherent',
        espace: 'public',
    })
  
    const formik = useFormik({
        initialValues: initialValues,

        validationSchema: Yup.object().shape({
            nom: Yup.string()
                .required('Nom obligatoire'),
            prenom: Yup.string()
                .required('Prénom obligatoire'),
            email: Yup.string().email('Adresse mail erronée')
                .required('Email obligatiore'),
            dateNaissance: Yup.date('Date naissance erronnée'),
            adresse: Yup.string()
                .required('Adresse obligatoire'),
            complements: Yup.string(),
            codePostal:Yup.string()
                .required('Code postal obligatoire')
                .max(5,'Code postal invalide')
                .matches('[0-9]{5}','Code postal invalide')
                ,
            ville: Yup.string()
                .required('Ville obligatoire')
        }),

        onSubmit: values => {
            setFlagSaisie('envoi');

            const options={
                values: formik.values,
                errorData: (data) => {
                    setFlagSaisie('saisie');
                    let span = document.getElementById("message");
                    span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);          
                },
                OKData: (data) => {
                    //action si OK
                    setFlagSaisie('AR');
                }
            }       
            
            axiosService.postAxios(options);        
            
        }
    });

    //formatage nom
    const nomBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.nom && e.target.value){
            formik.values.nom= e.target.value.toUpperCase();
        }
    }

    //Formatage n° de téléphone nn nn nn nn nn
    const telephoneBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.telephone && e.target.value){
            let telin= e.target.value
            let tel= telin.replace(/\s+/g, '');
            formik.values.telephone= tel.substr(0,2) + ' '+ tel.substr(2,2)+' '+tel.substr(4,2)+' '+tel.substr(6,2)+' '+tel.substr(8,2);
        }
    }

    return (
        <>
            <HeaderPublic />
            { 
                flagSaisie === 'saisie' &&
                <div id='bulletin' >
                    <div className='text-center mt-5 mb-5 col-6 offset-2'>
                        <h2 className='md-5 ' ><strong>Bulletin d'adhésion</strong></h2>
                    </div>
                    <div className='col-7 offset-2 text-center'>
                        <h5>Le montant de notre adhésion est de : 15€ pour les particuliers<br/> 5€ pour les chomeurs et les moins de 18ans<br/>30€ pour les personnes morales et les donateurs</h5>
                    </div>
                    <form className='col-5 offset-2'>
                        <fieldset>
                            <div className='mb-3'>
                                <h3 className='text-danger ms-1 strong' id='message' name='message'></h3>
                            </div>
                            <div className="mb-3 col-7">
                                <label for="nom" className="form-label">Nom *</label>
                                <input type="text" className="form-control" id="nom" placeholder="Votre nom"
                                    name='nom'
                                    onChange={formik.handleChange}
                                    value={formik.values.nom}
                                    onBlur={nomBlur}
                                />
                                {formik.touched.nom && formik.errors.nom && <span className='text-danger ms-1'>{formik.errors.nom}</span>}
                            </div>
                            <div className="mb-3 col-7">
                                <label for="prenom" className="form-label">Prénom *</label>
                                <input type="prenom" className="form-control" id="prenom" placeholder="Votre prenom"
                                    name='prenom'
                                    onChange={formik.handleChange}
                                    value={formik.values.prenom}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.prenom && formik.errors.prenom && <span className='text-danger ms-1'>{formik.errors.prenom}</span>}
                            </div>
                            <div className="mb-3 col-4">
                            <label for="dateNaissance" className="form-label">Date de naissance</label>
                            <input type="date" className="form-control" id="dateNaissance" placeholder="Votre dateNaissance"
                                name='dateNaissance'
                                onChange={formik.handleChange}
                                value={formik.values.dateNaissance}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.dateNaissance && formik.errors.dateNaissance && <span className='text-danger ms-1'>{formik.errors.dateNaissance}</span>}
                        </div>
                            <div className="mb-3 col-5">
                                <label for="email" className="form-label">Email *</label>
                                <input type="email" className="form-control" id="email" placeholder="Votre email"aria-describedby="emailHelp"
                                    name='email'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                            </div>
                            <div className="mb-3 col-4">
                            <label for="telephone" className="form-label">Téléphone</label>
                            <input type="text" className="form-control" id="telephone" placeholder="format nnnnnnnnn"
                                name='telephone'
                                onChange={formik.handleChange}
                                value={formik.values.telephone}
                                onBlur={telephoneBlur}
                            />
                            {formik.touched.telephone && formik.errors.telephone && <span className='text-danger ms-1'>{formik.errors.telephone}</span>}
                        </div>
                            <div className="mb-3">
                            <label for="adresse" className="form-label">Adresse *</label>
                            <input type="adresse" className="form-control" id="adresse" placeholder="Votre adresse"
                                onChange={formik.handleChange}
                                value={formik.values.adresse}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.adresse && formik.errors.adresse && <span className='text-danger ms-1'>{formik.errors.adresse}</span>}
                        </div>
                        <div className="mb-3">
                            <label for="complements" className="form-label">complements</label>
                            <input type="complements" className="form-control" id="complements" 
                                name='complements'
                                onChange={formik.handleChange}
                                value={formik.values.complements}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.complements && formik.errors.complements && <span className='text-danger ms-1'>{formik.errors.complements}</span>}
                        </div>

                        <div className="mb-3 col-3">
                            <label for="codePostal" className="form-label">Code postal *</label>
                            <input type="codePostal" className="form-control col-2" id="codePostal" placeholder="Code postal"aria-describedby="emailHelp"
                                name='codePostal'
                                onChange={formik.handleChange}
                                value={formik.values.codePostal}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.codePostal && formik.errors.codePostal && <span className='text-danger ms-1'>{formik.errors.codePostal}</span>}
                        </div>
                        <div className="mb-3">
                            <label for="ville" className="form-label">ville *</label>
                            <input type="ville" className="form-control" id="ville" placeholder="Ville"aria-describedby="emailHelp"
                                name='ville'
                                onChange={formik.handleChange}
                                value={formik.values.ville}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.ville && formik.errors.ville && <span className='text-danger ms-1'>{formik.errors.ville}</span>}
                        </div>
                            <p className='mt-3 mb-3'>* données obligatoires</p>
                            <button type="submit" className="btn btn-primary" onClick={formik.handleSubmit}>Envoyez</button>
                        </fieldset>
                    </form>
                </div>
            }
            {
                flagSaisie=== 'envoi' &&
                <div className='col-10 offset-6 ' style={{color: '#5f56c5'}}>
                    <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
                    <h4>Envoi en cours</h4>
                </div>
            }
            {
                flagSaisie=== 'AR' &&
                <div className='col-6 offset-2 text-center mt-5'>
                    <h3 className='mt-5'>Votre demande d'adhésion a été enregistrée</h3>
                    <p>Un mail de confirmation vous a été envoyé, surveillez bien vos spam</p>
                    <button onClick={()=>window.location.assign(window.routes.URLFront)}>Retour à l'accueil</button>             
                </div>
            }
            </>
    );
};

export default Contact;