import React from 'react';
import { useFormik} from 'formik';
import * as Yup from 'yup';

import './login.css'
import { axiosService } from '@/_services/account.axios';
import TitreH2 from '@/components/UI/titres/TitreH2';

const MdpOublie = () => {

    const formik = useFormik({
        initialValues: {
            email: '',
            domaine: 'front' ,
            action: 'mdpOublie'
        },

        validationSchema: Yup.object().shape({
            email: Yup.string().email('Adresse mail erronée')
                .required('Email obligatiore'),
            
    }),

    onSubmit: values => {
      values.domaine = 'front' ;
      values.action = 'mdpOublie';

      const options = {
        values: values,
        errorIdent: (data) => {
          let span = document.getElementById("message");
          span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
          let txt = document.createTextNode(data.message);
          span.appendChild(txt);        
        },
        OKData: (data) => {
            //action si OK
            console.log(data);
            localStorage.setItem('isMdpProv',true)
            window.location.href= '/AR/mdpOublie';
        }
      }

      axiosService.postAxios(options);
    }

  });

    return (
        <>
            <TitreH2 titre='Pour modifier votre mot de passe veuillez indiquer votre email'/>
            <form data-form-type="other">
                <div className='mb-2'>
                  <h3 className='text-danger col-1 offset-3 ' id='message' name='message'></h3>
                </div>
                <div className="mb-2">
                    <label for="email" className="col-1 offset-3">Email</label>
                    <input type="email" className="col-2" id="email" placeholder="Votre email"aria-describedby="emailHelp"
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                </div>
                <div>
                  <button type="submit" className="button mt-3 col-1 offset-4" onClick={formik.handleSubmit}>Envoyez</button>
                </div>
            </form>                        
        </>
    );
};

export default MdpOublie;