import React from 'react';

import './header.css'
import Logo from '../logo/Logo';

const HeaderPublic = () => {
    return (
        <header className='header'>
            <Logo/>
        </header>
    );
};

export default HeaderPublic;
