import axios from "axios";

import { accountService } from "./account.service";

const postAxios = (options)=>{

    const espace= accountService.isLogged() ? 'prive' : 'public';

    const defaults = {
        values:{
            domaine: 'front',
            action: 'test',
            espace: espace,
        },
        errorIdent: data =>{
            if(accountService.isLogged() && !localStorage.getItem('debug')){
                accountService.logOut();
                window.location.href='/AR/connectExpire';    
            }
            console.log(data);
            window.location.href= '/login/login';
        },
        errorData: data =>{
            console.log(data.message)
        },
        OKData: data =>{
            return(data)
        }
    }

    let paramsAxios= {...defaults,...options};
    paramsAxios.values.token= accountService.getToken()  

    axios.post(
        window.routes.URLAPI,
        paramsAxios.values,
        {
            headers:{
                'Content-Type':'multipart/form-data',
            }
        }
    )
    .then(reponse =>{
        
        if(!reponse.data.OKIdent){
            paramsAxios.errorIdent(reponse.data);
        }
        else{
            accountService.saveToken(reponse.data.token)     // on rafraîchit le token
        }
        if(!reponse.data.OKData){
            paramsAxios.errorData(reponse.data)
        }
        else{
            paramsAxios.OKData(reponse.data.data)
        }

    })
    .catch(err => console.log(err));
}


export const axiosService={postAxios};