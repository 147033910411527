import React, { useRef,useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik} from 'formik';
import * as Yup from 'yup';


import { axiosService } from '@/_services/account.axios';
import TitreH2 from '@/components/UI/titres/TitreH2';
import FormAdh from './FormAdh';
import Wait from '@/components/Wait';
import { accountService } from '../../../_services/account.service';


const Adherent = () => {

    const navigate = useNavigate()

    const flagMailValide= useRef(true);    //flag pour la vlaidation de l'email
    const statutMail= useRef();
    const libBouton= 'Créer'              // staut du retour de la verif mail;

    //l'adhérent peut-il modifier le profif si oui liste des profils
    let selectProfils= useRef()

    //valeurs par défaut pour le formulaire
    const [initialValues, setInitialValues] = useState( {      
        nom: '',
        prenom: '',
        dateNaissance:'',
        telephone:'',
        adresse: '',
        complements: '',
        codePostal: '',
        ville: '',
        situation:'demandee',
        dateAdhesion: '',
        montantAdhesion: '',
        modePaiement: '',
        idProfil: 9,    // adhérent
        emailValide: true,
        espace: accountService.isLogged() ? 'prive' : 'public',
    })

    const [flag,setFlag] = useState('wait')

    // Récupération des profils à l'affichage si mode prive
    useEffect(() => {
        if(!accountService.isLogged()){
            //mode public pas de maj profil
            selectProfils.current= {
                OKMajProfil: false,
                profils: ''
            }
            setFlag('adh');
        }     
        else{
            //mode privé
            if (flag === 'wait') {

                const options= {
                    values:{
                        domaine:'front',
                        action:'getNewAdherent',
                    },
                    OKData: (data)=>{
                        selectProfils.current= {
                            OKMajProfil: data.OKMajProfil,
                            profils: data.profils
                        }
                        setFlag('adh');
                    }
                    }
                axiosService.postAxios(options);
            }
         } 

        return;
    }, [])
 
    //valeurs par défaut pour le formulaire
    
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,


        validationSchema: Yup.object().shape({
            nom: Yup.string()
                .required('Nom obligatoire'),
            prenom: Yup.string()
                .required('Prénom obligatoire'),
            email: Yup.string().email('Adresse mail erronée')
                .required('Email obligatiore'),
            dateNaissance: Yup.date('Date naissance erronnée'),
                // .required('Date naissance obligatoire'),
            adresse: Yup.string()
                .required('Adresse obligatoire'),
            complements: Yup.string(),
            codePostal:Yup.string()
                .required('Code postal obligatoire')
                .max(5,'Code postal invalide')
                .matches('[0-9]{5}','Code postal invalide')
                ,
            ville: Yup.string()
                .required('Ville obligatoire')
        }),

        //MAJ BDD par requète API
        onSubmit: values => {

            values.domaine = 'front';    //Domaine API
            values.action = 'setNewAdherent';       // action API
            values.emailValide = flagMailValide.current ? 1 : 0;    //conversion en num

            const options = {
                values: values,
                errorData: (data) => {
                    let span = document.getElementById("message");
                    span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);          
                },
                OKData: (data) => {
                    //action si OK
                    setFlag('envoi');
                    navigate('/AR/adhesion');
                }
            }

            axiosService.postAxios(options);
        }
    }) 

    return (
        <>
            {
                flag==='adh' &&
                    <>
                        <TitreH2 titre= 'Enregister une adhésion' />
                            <h5 className='text-center '>Le montant de notre adhésion est de 15€ (30€pour les personnes morales et 5€ pour les chomeurs et les moins de 18 ans)</h5>
                        <div className='row mb-2 col-10 offset-2 '>
                            <p className='font-italic text-center' >
                                (Les bloqueurs de publicité sont suceptibles de masquer cette page. Si tel est le cas veuillez les désactiver pour ce site)
                            </p>
                        </div>
                        <div className='row mb-2 col-10 offset-2 '>
                            <h3 className='text-danger ms-1 h4 strong' id='message' name='message'></h3>
                        </div>
                        <FormAdh formik={{formik,flagMailValide,statutMail,libBouton,selectProfils}} />
                    </>
            }
            {   flag=== 'wait' &&  
                    <Wait />

            }
            {
                flag=== 'envoi' &&
                <div className='col-10 offset-6 ' style={{color: '#5f56c5'}}>
                    <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
                    <h4>Envoi en cours</h4>
                </div>
            }
         </>
    );
};

export default Adherent;