import React from 'react';
import { useFormik} from 'formik';
import * as Yup from 'yup';

import '@/pages/public/login/login.css'
import { axiosService } from '@/_services/account.axios';
import TitreH2 from '@/components/UI/titres/TitreH2';


const ChangeMdp = () => {
    //formulaire Formik

    const formik = useFormik({
        initialValues: {
            email: '',
            passwordProv: '',
            newPassword: '',
            confPassword: '',
            domaine: 'front',
            action: 'changeMdp'
        },

        //validation formulaire
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Adresse mail erronée')
            .required('Email obligatiore'),
            passwordProv: Yup.string()
            .required('Mot de passe provisoire obligatoire'),
            newPassword: Yup.string()
            .required('Nouveua mot de passe obligatoire')
            .min(7,'Le mot de passe doit avoir au moins 7 caractères'),
            confPassword: Yup.string()
            .required('Mot de passe provisoire obligatoire')
        }),

        //MAJ BDD par requète API
        onSubmit: values => {
            values.domaine = 'front';    //Domaine API
            values.action = 'changeMdp';       // action API

            // vérification égalité des mots de passe
            if(values.newPassword !== values.confPassword){
                let span = document.getElementById("message");
                let txt = document.createTextNode("Les mots de passe ne correspondent pas");
                span.appendChild(txt);
            }
            else{
                const options = {
                    values: values,
                    errorIdent: (data) => {
                        let span = document.getElementById("message");
                        span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
                        let txt = document.createTextNode(data.message);
                        span.appendChild(txt);        
                    },
                    OKData: (data) => {
                        //action si OK
                        console.log(data);
                        localStorage.setItem('isMdpProv',false)
                        window.location.href= '/AR/changeMdp';
                    }
                }
                axiosService.postAxios(options);
            } 

        }
    })

    return (
        <>
            <TitreH2 titre='Veuillez enregister votre nouveau mot de passe'/>

            <form data-form-type="other">
                <div className='mb-3 text-danger col-1 offset-4'>
                  <h3 id='message' name='message'></h3>
                </div>
                    <div className="mb-2">
                        <label for="email" className="col-2 offset-2">Email</label>
                        <input type="email" className="col-4" id="email" placeholder="Votre email"aria-describedby="emailHelp"
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="passwordProv" className="col-2 offset-2">Mot de passe provisoire</label>
                        <input type="text" className="col-3" id="passwordProv" placeholder="Mot de passe reçu par mail" autocomplete="off" data-dashlane-rid="a298491225ccf496" data-kwimpalastatus="alive" data-kwimpalaid="1691182305139-2" data-form-type="password"
                            name='passwordProv'
                            onChange={formik.handleChange}
                            value={formik.values.passwordProv}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.passwordProv && formik.errors.passwordProv && <span className='text-danger ms-1'>{formik.errors.passwordProv}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="newPassword" className="col-2 offset-2">Nouveau mot de passe</label>
                        <input type="password" className="col-3" id="newPassword" placeholder="Nouveau mot de passe" autocomplete="off" data-dashlane-rid="a298491225ccf496" data-kwimpalastatus="alive" data-kwimpalaid="1691182305139-2" data-form-type="password"
                            name='newPassword'
                            onChange={formik.handleChange}
                            value={formik.values.newPassword}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.newPassword && formik.errors.newPassword && <span className='text-danger ms-1'>{formik.errors.newPassword}</span>}
                    </div>
                    <div className="form-group">
                        <label for="confPassword" className="col-2 offset-2">Confirmez le mot de passe</label>
                        <input type="password" className="col-3" id="password" placeholder="Confirmez le pot de passe" autocomplete="off" data-dashlane-rid="a298491225ccf496" data-kwimpalastatus="alive" data-kwimpalaid="1691182305139-2" data-form-type="password"
                            name='confPassword'
                            onChange={formik.handleChange}
                            value={formik.values.confPassword}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.confPassword && formik.errors.confPassword && <span className='text-danger ms-1'>{formik.errors.confPassword}</span>}
                    </div>
                    <div>
                      <button type="submit" className="col-1 offset-4 button mt-3" onClick={formik.handleSubmit}>Envoyez</button>
                    </div>
                    <div className="col-2 offset-3 mt-3">
                        <a href="/login/mdpOublie">Réénitialiser le mot de passe</a>
                    </div>
            </form>            
        </>
    );
};

export default ChangeMdp;