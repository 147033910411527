import React, { useState } from 'react';
import { useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom'

import HeaderPublic from '@/components/header/HeaderPublic';
import { axiosService } from '@/_services/account.axios';
import './contact.css'


const Contact = () => {
    const navigate = useNavigate();
    const [flagMail,setFlagMail] = useState('mail');

    const [messageAR,setMessageAR] = useState('');
  
  
    const formik = useFormik({
        initialValues: {
            nom: '',
            prenom: '',
            email: '',
            message: '',
            domaine:'mail',
            action:'contact',
            idRole:10   //visiteur
        },

        validationSchema: Yup.object().shape({
            nom: Yup.string()
                .required('Nom obligatoire'),
            prenom: Yup.string()
                .required('Prénom obligatoire'),
            email: Yup.string().email('Adresse mail erronée')
                .required('Email obligatiore'),
            message: Yup.string()
                .max(500, 'Message trop long maxi 500 cars')
                .min(10,'message obligatoire > 10 car.')
        }),

        onSubmit: values => {
            setFlagMail('envoi');

            const options={
                values:{
                  domaine: 'mail',
                  action: 'contact',
                  message: formik.values.message,
                  email: formik.values.email,
                  nom: formik.values.nom,
                  prenom: formik.values.prenom
                },
                OKData: (data)=>{
                    //action si OK
                    navigate('/AR/contact');
                }
              }
        
              axiosService.postAxios(options);
        
            }
    });

    return (
        <>
                    <HeaderPublic />
        
            { 
                flagMail === 'mail' &&
                <div id='contact' className='col-8 offset-3'>
                    <h2 className='font-weight-bold text-center'><strong>Demande de renseignements</strong></h2>
                    <form className='col-8 offset-2'>
                        <fieldset>
                            <div className='mb-3'>
                                <h3 className='text-danger ms-1' id='message' name='message'></h3>
                            </div>
                            <div className="mb-3">
                                <label for="nom" className="form-label">Nom</label>
                                <input type="text" className="form-control" id="nom" placeholder="Votre nom"
                                    name='nom'
                                    onChange={formik.handleChange}
                                    value={formik.values.nom}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.nom && formik.errors.nom && <span className='text-danger ms-1'>{formik.errors.nom}</span>}
                            </div>
                            <div className="mb-3">
                                <label for="prenom" className="form-label">Prenom</label>
                                <input type="prenom" className="form-control" id="prenom" placeholder="Votre prenom"
                                    name='prenom'
                                    onChange={formik.handleChange}
                                    value={formik.values.prenom}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.prenom && formik.errors.prenom && <span className='text-danger ms-1'>{formik.errors.prenom}</span>}
                            </div>
                            <div className="mb-3">
                                <label for="email" className="form-label">Email</label>
                                <input type="email" className="form-control" id="email" placeholder="Votre email"aria-describedby="emailHelp"
                                    name='email'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='message' className='form-label' >Votre message</label>
                                <textarea className='form-control' style={{width:'500px',height:'200px'}} placeholder='Saisissez votre message ici' name='message' id='message'
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.message && formik.errors.message && <span className='text-danger ms-1'>{formik.errors.message}</span>}
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={formik.handleSubmit}>Envoyez</button>
                        </fieldset>
                    </form>
                </div>
            }
            {
                flagMail=== 'envoi' &&
                <div className='col-10 offset-6 ' style={{color: '#5f56c5'}}>
                    <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
                    <h4>Envoi en cours</h4>
                </div>
            }
            </>
    );
};

export default Contact;