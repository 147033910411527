import React from 'react';
import '@/App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Error from '@/components/errors/Error';

import Layout from '@/pages/public/Layout';
import Accueil from '@/pages/public/accueil/Accueil';
import AdherentsRouter from '@/pages/prive/adherents/AdherentsRouter'
import LoginRouter from '@/pages/public/login/LoginRouter';
import Contact from '@/pages/public/contact/Contact';
import BulletinAdhesion from '@/pages/public/adhesions/BulletinAdhesion.js';

import AR_router from '@/pages/public/AR/AR_router';

import MailRouter from '@/pages/prive/mail/MailRouter';

function App() {
  
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/bulletinAdhesion' element={<BulletinAdhesion />} />                
        <Route path='/contact' element={<Contact />} />

        <Route element={<Layout />}>
          <Route index='/'  element={<AdherentsRouter />} />
          
          <Route path='/accueil' element={<Accueil />} />

          <Route path='/adherents/*' element={<AdherentsRouter />} />
          <Route path='/login/*'  element={<LoginRouter />} />

          <Route path='/messagerie/*' element={<MailRouter />} />
          
          <Route path='/AR/:ar' element={<AR_router />} />
          <Route path='*' element={<Error type='404'>la page n'existe pas</Error>} />
        </Route>

      </Routes>
      </BrowserRouter>
    </div>

    );
}

export default App;
